
import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { withPreview } from 'gatsby-source-prismic'
// Components
import Layout from '../components/layout'
import CheckLink from '../components/checkLink'

export const query = graphql`
  query CaseStudy($id: String) {
    prismicCaseStudy(id: { eq: $id }) {
      ...PrismicCaseStudyFragment
    }
  }
`

const CaseStudyTemplate = ({ data, location }) => {
  if (!data) return null
  const caseStudyData = data.prismicCaseStudy.data
  return (

    <Layout>
      <div className='text-gray-800 bg-gray-100 text-lg'>
        <div className='relative overflow-hidden bg-gradient-to-r from-warmGray-800 to-coolGray-600'>
          <div className='max-w-screen-xl mx-auto'>
            <div className={`relative pb-12 sm:pb-16 md:pb-24 ${caseStudyData.page_image.fluid && ('lg:w-1/2')}`}>
              <div className='mx-auto max-w-screen-xl px-4 mt-32 sm:px-6 md:mt-40 lg:px-8 xl:mt-40 lg:text-left sm:text-center space-y-6 text-white'>
                {caseStudyData.page_title && <div className='hero' dangerouslySetInnerHTML={{__html:caseStudyData.page_title.html}} />}
                {caseStudyData.page_text && <div dangerouslySetInnerHTML={{__html:caseStudyData.page_text.html}} />}
                {(data.page_button_link && data.page_button_text) && <CheckLink className='button' link={caseStudyData.page_button_link}>{caseStudyData.page_button_text}</CheckLink>}
              </div>
            </div>
          </div>
          {caseStudyData.page_image.fluid &&
            <div className='polygon lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
              <Img className='w-full h-full object-cover' fluid={caseStudyData.page_image.fluid} alt={caseStudyData.page_image.alt} />
            </div>
          }
        </div>
      </div>
      <div className='max-w-screen-md w-full mx-auto px-4 py-8'>
        <div className='prose prose-lg' dangerouslySetInnerHTML={{__html:caseStudyData.text.html}} />
      </div>
      <div className='bg-coolGray-100'>
        <div className='max-w-screen-md mx-auto py-12 px-4 md:py-8 lg:flex lg:items-center lg:justify-between'>
          <h2 className='text-3xl text-gray-900 sm:text-4xl'>
            <span className='block'>Interested in our services?</span>
            <span className='block text-coolGray-600'>Get in touch today.</span>
          </h2>
          <div className='mt-8 flex lg:mt-0 lg:flex-shrink-0'>
            <div className='inline-flex'>
              <Link
                className='button inline-flex items-center justify-center text-white bg-gradient-to-r from-red-500 to-red-800'
                to='/contact'>
                Get In Touch
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default withPreview(CaseStudyTemplate)
